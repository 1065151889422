<div class="container-fluid" *ngIf="!showOrHideLoader">
  <div class="row bmi mx-auto">
    <div class="col d-flex justify-content-center">
      <div class="card login-text card-container card-margin">
        <div class="card-text-container">
          <h1 class="sr-only">Login</h1>                  
          <p>
            <b>
              Expect intermittent outages Saturday, February 1 between 7 AM and 9 AM MST for system maintenance.
            </b>
          </p>
          <p>
          <p>
            <a href="http://carf.adobeconnect.com/r22cci/" target="_blank">Click here</a> for a short tutorial on Customer Connect.
          </p>
        </div>
      </div>
      <div class="card card-container">
        <div class="row carf-image">
          <div class="col-md-12">
            <img
              class="img-fluid"
              src="../../../assets/images/CARF_International_logo_CC.png"
              alt="card hero section"
            />
          </div>
        </div>
        <div class="row login-text login-text-styling">
          <div class="col-md-12">
            <strong>
              Please note: Customer Connect will be unavailable on Saturday evenings from 9:30 PM -
              3:00 AM Arizona Time (UTC-7:00) in order to perform system maintenance.
            </strong>
          </div>
        </div>
        <div class="row login-button-styling">
          <div class="col-md-12">
            <button class="saveButton" (click)="login()">
              <i class="fa fa-sign-in-alt login-icon" aria-hidden="true"></i>
              <span class="login-button-text">Login</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showOrHideLoader">
  <app-loader></app-loader>
</div>
